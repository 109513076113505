<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',

}
</script>

<style>
html {
  background-color: #000;
  color: #fff;
  display: flex;
  flex-direction: column;
  background-size: cover;
  background-attachment: fixed;




}
#app {

  height: 100vh;
 
  scrollbar-width: none; 
  -ms-overflow-style: none; 
  -webkit-overflow-scrolling: touch;

}
*{
  -webkit-touch-callout: none;
  -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

::-webkit-scrollbar {
  width: 8px;
  height: 4px;

}

::-webkit-scrollbar-track {
 background-color: #000000;
}

::-webkit-scrollbar-thumb {
  background-color: #8a57f2;

}

::-webkit-scrollbar-thumb:window-inactive {
  background: #8a57f2;
}

@media screen and (max-width: 1920px) {
  }
  @media screen and (max-width: 1680px) {
  }
  @media screen and (max-width: 1480px) {
  }
  @media screen and (max-width: 1280px) {
    html,body,.mainwidth{
        width:100vw;
    }
  }
  @media screen and (max-width: 992px) {
  }
  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 660px) {
  }
  @media screen and (max-width: 580px) {
  }
  @media screen and (max-width: 480px) {
   body{ .about{
        .mainwidth{
            >div{
                flex-direction: column;
                img.arrow{
                    width:60px;
                }
            }
        }
    }
  }
}
</style>
